import React, { ReactNode } from 'react'
import classNames from 'classnames'
import Headings, { HeadingsProps } from 'Components/Headings/Headings'
import Row from 'Components/Row/Row'
import Text from 'Components/texts/Text'
import { fadeUp } from 'Utils/animations'
import { HomepageDataFragment } from 'Typings/generated-gatsby-types'
import styles from './SectionContent.module.scss'

export type ContentID = 'about' | 'web' | 'mobile' | 'design' | 'consult'

export type SectionContentProps = Partial<Omit<HeadingsProps, 'className'>> & {
  className?: string
  headingsClassName?: string
  textClassName?: string
  textsClassName?: string
  data: HomepageDataFragment
  id: ContentID
  renderAtTextRight?: () => ReactNode
}

export const SectionContent = (props: SectionContentProps) => {
  const {
    className = '',
    headingsClassName = '',
    textClassName = '',
    textsClassName = '',
    id,
    data,
    renderAtTextRight,
    isInvert,
    ...headingsProps
  } = props

  return (
    <div className={classNames([styles.root, className])}>
      <Headings
        data={data[`${id}_caption`]}
        className={headingsClassName}
        isInvert={isInvert}
        {...headingsProps}
      />
      <Row className={classNames([styles.texts, textsClassName])} vertical="top">
        <Text
          className={classNames([
            styles.text,
            textClassName,
            { [styles.invert]: isInvert },
          ])}
          {...fadeUp()}
        >
          {data[`${id}_text`]}
        </Text>
        {renderAtTextRight?.()}
      </Row>
    </div>
  )
}
