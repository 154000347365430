import React, { useRef } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IndexSectionProps } from './IndexPage'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { ContentID, SectionContent } from './SectionContent'
import Text from 'Components/texts/Text'
import { ValueOf } from 'Typings/common'
import {
  getIllustrationsClassName,
  ILLUSTRATION_TYPES,
  Illustrations,
} from 'Components/Illustrations/Illustrations'
import Cards from 'Assets/cards.inline.png'
import styles from './SectionServices.module.scss'

const keys: Array<ContentID> = ['web', 'mobile', 'design', 'consult']

const ILLUSTRATIONS: Record<string, ValueOf<typeof ILLUSTRATION_TYPES>> = {
  web: ILLUSTRATION_TYPES.web,
  mobile: ILLUSTRATION_TYPES.phones,
  design: ILLUSTRATION_TYPES.design,
} as const

export type SectionServicesProps = IndexSectionProps & {
  contentClassName?: string
}

export const SectionServices = (props: SectionServicesProps) => {
  const { data, index, contentClassName = '' } = props

  const root = useRef<HTMLElement>(null)

  const [t] = useTranslation()

  const id = keys[index]

  const isConsult = id === 'consult'

  const isInvert = index % 2 !== 0

  const type = ILLUSTRATIONS[id]

  return (
    <BaseContainer
      ref={root}
      id={id}
      vertical="xs"
      Component="section"
      contentClassName={classNames([styles.content, contentClassName])}
      className={classNames([
        styles.root,
        styles[id],
        {
          [styles.invert]: isInvert,
          [getIllustrationsClassName(styles, type)]: !isConsult,
        },
      ])}
    >
      <div className={styles.wrapper}>
        <Text variant="sm" className={styles.top}>
          {`[ 0${index + 1} ] ${t(`service.${id}`)}`}
        </Text>
        <SectionContent
          id={id}
          data={data}
          isInvert={isInvert}
          textClassName={styles.text}
          descriptionClassName={styles.description}
        />
      </div>
      <div className={styles.imageWrapper}>
        {isConsult ? <img className={styles.image} src={Cards} alt="consult" /> : null}
      </div>
      {!isConsult && <Illustrations isCustom container={root} type={type} />}
    </BaseContainer>
  )
}
