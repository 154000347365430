import React from 'react'
import classNames from 'classnames'
import { IndexSectionProps } from 'Views/index/IndexPage'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import CountTexts from 'Components/CountTexts/CountTexts'
import { SectionContent } from './SectionContent'
import { fadeUp } from 'Utils/animations'
import styles from './SectionAbout.module.scss'

export type SectionAboutProps = IndexSectionProps & {
  contentClassName?: string
}

const SectionAbout = (props: SectionAboutProps) => {
  const { data, contentClassName = '' } = props

  const renderCounts = () => {
    return data.about_counts.map((info, index) => (
      <div key={index} className={styles.infoItem}>
        <CountTexts
          className={styles.info}
          countClassName={styles.count}
          {...info}
          {...fadeUp({ index: index + 1 })}
        />
      </div>
    ))
  }

  return (
    <BaseContainer
      className={styles.root}
      contentClassName={classNames([styles.content, contentClassName])}
      Component="section"
    >
      <SectionContent
        id="about"
        data={data}
        textClassName={styles.text}
        textsClassName={styles.texts}
        descriptionClassName={styles.description}
        headingClassName={styles.heading}
        headingsClassName={styles.headings}
        renderAtTextRight={renderCounts}
      />
    </BaseContainer>
  )
}

export default SectionAbout
