import React from 'react'
import classNames from 'classnames'
import { FormSection } from 'Components/sections/FormSection'
import styles from './SectionForm.module.scss'

export type SectionFormProps = {
  className?: string
  contentClassName?: string
}

export const SectionForm = (props: SectionFormProps) => {
  const { className = '', contentClassName = '' } = props

  return (
    <FormSection
      className={classNames([styles.root, className])}
      contentClassName={classNames([styles.content, contentClassName])}
    />
  )
}
