import React, { Fragment } from 'react'
import classNames from 'classnames'
import size from 'lodash/size'
import { IndexSectionProps } from './IndexPage'
import { Accordion } from 'Components/Accordion/Accordion'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { Divider } from 'Components/Divider/Divider'
import { fadeUp } from 'Utils/animations'
import styles from './SectionFAQ.module.scss'

export type SectionFAQProps = IndexSectionProps & {
  className?: string
}

export const SectionFAQ = (props: SectionFAQProps) => {
  const { className = '', data } = props

  if (!data || size(data.faq) === 0) {
    return null
  }

  return (
    <BaseContainer
      className={classNames(styles.root, className)}
      contentClassName={styles.content}
      Component="section"
    >
      {data.faq.map(({ heading, description }, index) => (
        <Fragment key={heading}>
          <div {...fadeUp({ index })} className={styles.wrapper}>
            <Accordion index={index + 1} title={heading} text={description} />
          </div>
          {index < size(data.faq) - 1 && <Divider className={styles.divider} />}
        </Fragment>
      ))}
    </BaseContainer>
  )
}
