import React from 'react'
import classNames from 'classnames'
import { IndexSectionProps } from 'Views/index/IndexPage'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { InfiniteIconsLine } from 'Components/InfiniteIconsLine/InfiniteIconsLine'
import Headings from 'Components/Headings/Headings'
import Row from 'Components/Row/Row'
import Button from 'Components/touchables/Button'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { fadeUp } from 'Utils/animations'
import Popup from 'Components/Popup/Popup'
import { ConsultPopup } from 'Components/popups/Consult/ConsultPopup'
import styles from './SectionConsult.module.scss'

const showConsultPopup = () => {
  Popup.show({ renderContent: (data: any) => <ConsultPopup {...data} /> })
}

export type SectionConsultProps = IndexSectionProps & {
  contentClassName?: string
}

const SectionConsult = (props: SectionConsultProps) => {
  const { data, contentClassName = '' } = props

  const [t] = useTranslation()

  return (
    <BaseContainer
      className={styles.root}
      contentClassName={classNames([styles.content, contentClassName])}
      Component="section"
    >
      <Headings
        data={data.consultation_caption}
        descriptionClassName={styles.description}
        HeaderComponent="h1"
        titleVariant="heading-big"
        isInvert
        center
      />
      <div className={styles.wrapper}>
        <Row horizontal="center">
          <Button
            title={t('consult.button')}
            className={styles.button}
            theme="secondary"
            onClick={showConsultPopup}
            {...fadeUp()}
          />
        </Row>
        <InfiniteIconsLine className={styles.line} />
      </div>
    </BaseContainer>
  )
}

export default SectionConsult
