import React, { ReactNode, Ref, PureComponent } from 'react'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import SvgIcon from 'Components/SvgIcon/SvgIcon'
import Close from 'Assets/close.inline.svg'
import styles from './Popup.module.scss'

let popupRef: any = null

export type PopupProps = {
  className?: string
}

export type PopupContentType = {
  renderCloseIcon: (className?: string) => ReactNode
  close: () => void
}

export type PopupStateType = {
  open: boolean
  paperClassName?: string
  renderContent?: null | ((data: PopupContentType) => ReactNode)
}

const initialState = { renderContent: null, paperClassName: '' }

class Popup extends PureComponent<PopupProps> {
  state: PopupStateType = { open: false, ...initialState }

  close = () => {
    document.querySelector('html')?.classList.remove('no-scroll')
    this.setState({ open: false })
  }

  onExited = () => {
    document.querySelector('html')?.classList.remove('no-scroll')
    this.setState(initialState)
  }

  show = (data: Record<string, any>) => {
    document.querySelector('html')?.classList.add('no-scroll')
    this.setState({ ...initialState, open: true, ...data })
  }

  static setRef: (ref: Ref<any>) => Ref<any>
  static show: (data?: Record<string, any>) => any

  renderCloseIcon = (className: string = '') => (
    <SvgIcon
      Icon={Close}
      className={classNames([styles.close, className])}
      onClick={this.close}
    />
  )

  render() {
    const { open, renderContent, paperClassName = '' } = this.state
    const { className = '' } = this.props

    return (
      <Dialog
        classes={{
          root: styles.confirmRoot,
          paper: classNames(styles.paper, paperClassName),
        }}
        open={open}
        onClose={this.close}
        TransitionProps={{ onExited: this.onExited }}
      >
        <div className={classNames('popup', styles.root, className)}>
          {renderContent?.({ renderCloseIcon: this.renderCloseIcon, close: this.close })}
        </div>
      </Dialog>
    )
  }
}

Popup.setRef = (ref: Ref<any>) => (popupRef = ref)

Popup.show = (data: Record<string, any> = {}) => popupRef?.show(data)

export default Popup
