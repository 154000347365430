import React, { FunctionComponent, SVGProps } from 'react'
import classNames from 'classnames'
import { useI18next } from 'gatsby-plugin-react-i18next'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Row from 'Components/Row/Row'
import Text from 'Components/texts/Text'
import BackTextRU from 'Assets/back-text-ru.inline.svg'
import BackTextEN from 'Assets/back-text-en.inline.svg'
import IconPen from 'Assets/icon-pen.inline.svg'
import { fade, fadeUp } from 'Utils/animations'
import { scrollToElem } from 'Utils/common'
import styles from './SectionMain.module.scss'

export type ServiceType = {
  title: string
  sub: string
  section: string
}

export type ServiceTextType = Array<ServiceType>

export type SvgType = FunctionComponent<
  SVGProps<SVGSVGElement & { className?: string | undefined }>
>

const BACK_TEXT_BY_LOCALE: Record<string, SvgType> = {
  ru: BackTextRU,
  en: BackTextEN,
}

export type SectionMainProps = {
  contentClassName?: string
}

const SectionMain = (props: SectionMainProps) => {
  const { contentClassName = '' } = props

  const { t, i18n } = useI18next()

  const _services = t('services', { returnObjects: true }) as ServiceTextType
  const services = Array.isArray(_services) ? _services : []

  const BackText = BACK_TEXT_BY_LOCALE[i18n.resolvedLanguage ?? ''] ?? BackTextRU

  const onClickService = ({ section }: ServiceType) => {
    scrollToElem(document.getElementById(section) as HTMLDivElement)
  }

  return (
    <BaseContainer
      className={styles.root}
      contentClassName={classNames([styles.content, contentClassName])}
      Component="section"
    >
      <div className={styles.backTextContainer}>
        <BackText className={styles.backText} {...fade({ duration: 1000 })} />
        <div className={styles.dividerContainer}>
          <div className={styles.divider}>
            <IconPen className={styles.iconPen} />
          </div>
        </div>
      </div>
      <Row className={styles.services}>
        {services.map((service, index) => (
          <div
            key={index}
            className={styles.service}
            onClick={() => onClickService(service)}
            {...fadeUp({ index: index + 1 })}
          >
            <Text variant="xs" className={styles.index}>{`[ 0${index + 1} ]`}</Text>
            <Text variant="lg-bold" className={styles.title}>
              {service.title}
            </Text>
            <Text variant="sm" className={styles.sub}>
              {service.sub}
            </Text>
          </div>
        ))}
      </Row>
    </BaseContainer>
  )
}

export default SectionMain
