import React from 'react'
import classNames from 'classnames'
import { PopupContentType } from 'Components/Popup/Popup'
import { ContactForm } from 'Components/Forms/ContactForm/ContactForm'
import styles from './ConsultPopup.module.scss'

export type ConsultPopupProps = PopupContentType & {
  className?: string
}

export const ConsultPopup = (props: ConsultPopupProps) => {
  const { className = '', close, renderCloseIcon } = props

  return (
    <div className={classNames([styles.root, className])}>
      <ContactForm onSubmit={close} />
      {renderCloseIcon()}
    </div>
  )
}
