import React from 'react'
import classNames from 'classnames'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Headings from 'Components/Headings/Headings'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ArticlesData } from 'Utils/common'
import { ArticlesRow } from 'Components/Article/ArticlesRow'
import { fadeUp } from 'Utils/animations'
import Button from 'Components/touchables/Button'
import { PATHS } from 'Utils/routing'
import Row from 'Components/Row/Row'
import styles from './SectionArticles.module.scss'

export type SectionArticlesProps = {
  articlesData: ArticlesData
  contentClassName?: string
}

const SectionArticles = (props: SectionArticlesProps) => {
  const { articlesData, contentClassName } = props

  const [t] = useTranslation()

  if (!articlesData) {
    return null
  }

  return (
    <BaseContainer
      className={styles.root}
      contentClassName={classNames([styles.content, contentClassName])}
      Component="section"
    >
      <Headings
        data={t('article.home', { returnObjects: true })}
        descriptionClassName={styles.description}
      />
      <ArticlesRow className={styles.articles} articlesData={articlesData} />
      <Row horizontal="center" {...fadeUp({ index: 4 })}>
        <Button title={t('article.show_all')} variant="outline" link={PATHS.articles} />
      </Row>
    </BaseContainer>
  )
}

export default SectionArticles
