import React from 'react'
import classNames from 'classnames'
import Row from 'Components/Row/Row'
import Icon1 from 'Assets/slider/icon-1.inline.svg'
import Icon2 from 'Assets/slider/icon-2.inline.svg'
import Icon3 from 'Assets/slider/icon-3.inline.svg'
import Icon4 from 'Assets/slider/icon-4.inline.svg'
import Icon5 from 'Assets/slider/icon-5.inline.svg'
import Icon6 from 'Assets/slider/icon-6.inline.svg'
import Icon7 from 'Assets/slider/icon-7.inline.svg'
import Icon8 from 'Assets/slider/icon-8.inline.svg'
import Icon9 from 'Assets/slider/icon-9.inline.svg'
import Icon10 from 'Assets/slider/icon-10.inline.svg'
import Icon11 from 'Assets/slider/icon-11.inline.svg'
import Icon12 from 'Assets/slider/icon-12.inline.svg'
import Icon13 from 'Assets/slider/icon-13.inline.svg'
import Icon14 from 'Assets/slider/icon-14.inline.svg'
import Icon15 from 'Assets/slider/icon-15.inline.svg'
import Icon16 from 'Assets/slider/icon-16.inline.svg'
import Icon17 from 'Assets/slider/icon-17.inline.svg'
import Icon18 from 'Assets/slider/icon-18.inline.svg'
import Icon19 from 'Assets/slider/icon-19.inline.svg'
import styles from './InfiniteIconsLine.module.scss'

const icons = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Icon18,
  Icon19,
]

const allIcons = [...icons, ...icons, ...icons]

export type InfiniteIconsLineProps = {
  className?: string
}

export const InfiniteIconsLine = (props: InfiniteIconsLineProps) => {
  const { className = '' } = props

  return (
    <div className={classNames([styles.root, className])}>
      <Row className={styles.content}>
        {allIcons.map((Icon, index) => (
          <Icon key={index} className={styles.icon} />
        ))}
      </Row>
    </div>
  )
}
