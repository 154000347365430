import React, { useState, useCallback, useEffect, ReactNode } from 'react'
import classNames from 'classnames'
import omit from 'lodash/omit'
import isFinite from 'lodash/isFinite'
import Collapse from '@material-ui/core/Collapse'
import Text, { TextProps } from 'Components/texts/Text'
import SvgIcon from 'Components/SvgIcon/SvgIcon'
import ChevronDown from 'Assets/chevron-down.inline.svg'
import styles from './Accordion.module.scss'
import Row from 'Components/Row/Row'

export type AccordionItemType = {
  title?: string
  text?: string
}

export type AccordionProps = AccordionItemType & {
  id?: number
  activeId?: number
  className?: string
  chevronClassName?: string
  defaultOpen?: boolean
  titleParentProps?: Record<string, any>
  titleProps?: TextProps
  textProps?: TextProps
  isOpenOnlyOne?: boolean
  onOpen?: (id?: number) => void
  contentClassName?: string
  index?: number
}

export const Accordion = (props: AccordionProps) => {
  const {
    id,
    activeId,
    title = '',
    text = '',
    className = '',
    chevronClassName = '',
    defaultOpen = false,
    titleParentProps = {},
    titleProps = {},
    textProps = {},
    isOpenOnlyOne = false,
    onOpen,
    contentClassName = '',
    index,
  } = props

  const [isOpen, setIsOpen] = useState(defaultOpen)

  const hasContent = Boolean(text)
  const isVisible = Boolean(title) && hasContent

  useEffect(() => {
    isOpenOnlyOne && activeId !== id && setIsOpen(false)
  }, [activeId])

  useEffect(() => {
    isOpen && onOpen?.(id)
  }, [isOpen])

  const toggle = useCallback(() => setIsOpen((p) => !p), [])

  if (!isVisible) {
    return null
  }

  const indexText = isFinite(index) ? (
    <Text variant="heading" className={classNames([styles.index, styles.headerText])}>
      {`[ ${index! < 10 ? `0${index}` : index} ]`}
    </Text>
  ) : null

  return (
    <div className={classNames([styles.root, className])}>
      <Row
        className={classNames([styles.header, { [styles.opened]: isOpen }])}
        onClick={toggle}
        vertical="top"
      >
        {indexText}
        <Text
          variant="heading"
          Component="h2"
          className={classNames([styles.title, styles.headerText])}
        >
          {title}
        </Text>
        <SvgIcon
          Icon={ChevronDown}
          className={classNames([styles.chevron, chevronClassName])}
        />
      </Row>
      <Collapse in={isOpen}>
        <Row vertical="top" className={classNames([styles.content, contentClassName])}>
          {indexText}
          <Text variant="sm" className={styles.text}>
            {text}
          </Text>
        </Row>
      </Collapse>
    </div>
  )
}
