import React from 'react'
import IndexPage from 'Views/index/IndexPage'
import { graphql, PageProps } from 'gatsby'
import { HomepageQuery } from 'Typings/generated-gatsby-types'

const Index = (props: PageProps<HomepageQuery>) => {
  const { strapiHomepage, allStrapiArticle } = props.data || {}

  return <IndexPage data={strapiHomepage} articlesData={allStrapiArticle} />
}

export const query = graphql`
  fragment HomepageData on StrapiHomepage {
    seo {
      meta_description
      meta_title
      meta_image {
        localFile {
          publicURL
        }
      }
    }
    consultation_caption {
      heading
      description
    }
    about_caption {
      heading
      description
    }
    about_text
    about_counts {
      count
      heading
      description
    }
    web_caption {
      heading
      description
    }
    web_text
    mobile_caption {
      heading
      description
    }
    mobile_text
    design_caption {
      heading
      description
    }
    design_text
    consult_caption {
      heading
      description
    }
    consult_text
    portfolio_caption {
      heading
      description
    }
    faq {
      heading
      description
    }
    projects {
      project {
        id
        name
        short_info
        description
        slug
        years
        work_type
        app_type
        show_overlay
        preview {
          localFile {
            publicURL
          }
        }
        views {
          localFile {
            publicURL
          }
        }
      }
    }
  }

  query($language: String!) {
    strapiHomepage(locale: { eq: $language }) {
      ...HomepageData
    }
    allStrapiArticle(filter: { locale: { eq: $language } }) {
      edges {
        node {
          id
          title
          slug
          locale_key
          content
          main_tag
          additional_tags
          created_at
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Index
