import React from 'react'
import { HeaderThemeOnScrollChanger } from 'Components/layout/Header'
import { HomepageDataFragment } from 'Typings/generated-gatsby-types'
import Layout from 'Components/layout/Layout'
import SectionMain from 'Views/index/SectionMain'
import SectionConsult from 'Views/index/SectionConsult'
import { SectionServices } from 'Views/index/SectionServices'
import { SectionForm } from 'Views/index/SectionForm'
import { SectionFAQ } from 'Views/index/SectionFAQ'
import { SectionPortfolio } from 'Views/index/SectionPortfolio'
import SectionAbout from 'Views/index/SectionAbout'
import SectionProjects from 'Views/index/SectionProjects'
import SectionArticles from 'Views/index/SectionArticles'
import Menu from 'Components/layout/Menu'
import SEO from 'Components/SEO'
import { ArticlesData, useMaybeChangeLanguageOnInit } from 'Utils/common'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Popup from 'Components/Popup/Popup'
import styles from './IndexPage.module.scss'

export type IndexSectionProps = {
  data: HomepageDataFragment
  index: number
}

export type IndexPageProps = IndexSectionProps & {
  articlesData: ArticlesData
}

const SECTIONS = [
  SectionMain,
  SectionConsult,
  SectionAbout,
  SectionServices,
  SectionServices,
  SectionServices,
  SectionServices,
  // SectionPortfolio,
  SectionProjects,
  SectionFAQ,
]

const SECTIONS_THEMES: Array<'light' | 'dark'> = [
  'dark', // SectionMain
  'dark', // SectionConsult
  'light', // SectionAbout
  'light', // SectionServices
  'dark', // SectionServices
  'light', // SectionServices
  'dark', // SectionServices
  // 'light', // SectionPortfolio main
  // 'light', // SectionPortfolio filler
  'dark', // SectionProjects
  'light', // SectionFAQ
]

const IndexPage = (props: IndexPageProps) => {
  const { data, articlesData } = props

  const isInit = useMaybeChangeLanguageOnInit()

  const { language } = useI18next()

  // console.log('IndexPage', data)

  if (!data || !isInit) {
    return null
  }

  const isRussian = language === 'ru'
  const themes = [...SECTIONS_THEMES, isRussian ? 'light' : null, 'light'].filter(Boolean)

  return (
    <>
      <Menu />
      <Layout className={styles.root}>
        <SEO data={data.seo} />
        <HeaderThemeOnScrollChanger themes={themes as Array<'light' | 'dark'>} />
        {SECTIONS.map((Section, index) => (
          <Section
            key={index}
            data={data}
            index={index > 2 && index < 7 ? index - 3 : index}
            contentClassName={styles.content}
          />
        ))}
        {isRussian && (
          <SectionArticles
            articlesData={articlesData}
            contentClassName={styles.content}
          />
        )}
        <SectionForm contentClassName={styles.content} />
      </Layout>
      {/* @ts-ignore */}
      <Popup ref={Popup.setRef} />
    </>
  )
}

export default IndexPage
